import { Environment } from './models/environment.interface';
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=qa` then `environment.qa.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment: Environment = {
  appName: 'Data Insights INT Environment',
  envName: 'INT',
  production: false,
  apiBase: 'https://api.datainsights.int.ert.com',
  version: 'develop-12092024-13:38:51',
};

